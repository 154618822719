<template>
  <div class="page-content detailed" v-if="collection!==null">

    <reiseplan-detail-banner :collection="collection" :contents="contents"/>
    <div class="container">
      <div class="row">

        <div class="col-12 col-lg-12 pr-lg-3">

          <div class="row mb-4">
            <div class="col-12">
              <p>{{ collection.description }}</p>
            </div>
          </div>

          <div class="row cst-margin">
            <div class="col-12">
              <div class="locations">
                <multiple-map v-if="this.contents!==null && this.contents.length>0" :contents="contents" class="locations__map" ref="theMap" @highlight="highlightThisContent"></multiple-map>
                <div class="locations__slider">
                  <div class="card-style" v-if="contents!==null" v-for="(content, index) in contents" :key="index">
                    <event-card v-if="content.content_type_name=='angebot'" :item="content" :desktopAuto="true" />
                    <location-card v-if="content.content_type_name=='institution' || content.content_type_name=='pois'" :item="content" :desktopAuto="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="col-12 col-lg-3 pl-lg-3" style="display: none;">
          <div class="reactions">
            <h6 class="title">Reaktionen</h6>
            <div class="reactions-list">
              <div class="d-flex">
                <div class="reactions-list__icon">
                  <span class="material-icons">person</span>
                </div>
                <div class="reactions-list__info">
                  <h6>VikingFan0815</h6>
                  <p>Klasse Museum, kann ich jedem Wikingerfan empfehlen! Habe mich fast selbst wie ein Wikinger gefühlt ;-)</p>
                </div>
              </div>
            </div>
            <div class="reactions-list">
              <div class="d-flex">
                <div class="reactions-list__icon">
                  <span class="material-icons">person</span>
                </div>
                <div class="form-group mb-0">
                  <textarea name="reaction" class="form-control" id="" placeholder="Füge eine Reaktion hinzu ..." cols="30" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import Resource from '@/api/resource';
  import { VueperSlides, VueperSlide } from 'vueperslides';
  import 'vueperslides/dist/vueperslides.css';
  import { showLoader, hideLoader, getFirstFieldValue } from '@/utils/helpers';
  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';
  const collectionResource = new Resource('collections');

  export default {
    name: 'ReiseplanView',
    components: {
      LocationCard: () => import('@/components/cards/LocationCard'),
      EventCard: () => import('@/components/cards/EventCard'),
      MultipleMap: () => import('@/components/map/multiple.vue'),
      ReiseplanDetailBanner: () => import('./components/ReiseplanDetailBanner'),
      ShareButton: () => import('@/components/controls/ShareButton'),
      BookmarkButton: () => import('@/components/controls/BookmarkButton'),
      VueperSlides,
      VueperSlide
    },
    data() {
      return {
        collection: null,
      }
    },
    computed: {
      id() {
        return this.$route.params.id;
      },
      contents(){
        if(this.collection!=null && this.collection.contents!=null && this.collection.contents.length>0){
          return this.collection.contents;
        }
        return null;
      },
    },
    created() {
      this.getCollection();
    },
    methods: {
      showLoader,
      hideLoader,
      getCategoryPlaceholderImage,
      getFirstFieldValue,
      getCollection() {
        this.loader = this.showLoader(this.loader);
        collectionResource.get(this.id)
        .then(response => {
          this.collection = response.data;
        })
        .catch(error => {
          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Keine Berechtigung',
            text: 'Sie haben nicht die benötigten Berechtigungen um diese Liste einzusehen.'
          });

          if(this.user==null){
            this.$router.push({name:'login'});
          }

        })
        .finally(() => {
          this.loader = this.hideLoader(this.loader);
        });
      },
      highlightThisContent(content){
        this.$emit('highlight',{sender:'map', content: content});
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content .locations__slider{
    width: 100%;
    max-width: 300px;

    @media (max-width: 991px){ 
      max-width: 100%;
      padding-left: 10px;
    }

  }


  .footer-margin{
    @include responsive($scroll-breakpoint) {
      margin-bottom: 0vh;
    }
  }

  .page-content.detailed {
    @include responsive($scroll-breakpoint) {
      padding-bottom: 80px;
    }

  }

  .card-style {
    & a {
      height: 100%;
    }
  }

  .card-desktop {
    min-width: 250px !important;
  }

</style>